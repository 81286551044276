export enum Context {
	MODAL = "modal",
	PAGE = "page",
	ACTIONS = "actions",
	ANALYTICS = "analytics",
	ADS = "ads",
	PLAYLISTS = "playlists",
	PLAYLIST_ACTION = "playlist_action",
	NONE = ""
}

export enum AspectRatios {
	NINE_SIXTEEN = "9:16",
	ONE_ONE = "1:1",
	FOUR_THREE = "4:3",
	SIXTEEN_NINE = "16:9"
};

export enum AuthErrorTypes { TWO_FACTOR_REQUIRED = "two_factor_required", INVALID_CREDENTIALS = "invalid_credentials" };

export enum DateFilters {
	SEVENDAYS = "0",
	THIRTYDAYS = "1",
	NINETYDAYS = "2",
	ONEYEAR = "3"
};

export enum DeploymentType {
	EVENT = "event",
	SCHEDULE = "schedule"
};

export enum DeploySteps {
	CREATE_NEW = "create",
	SCHEDULE = "schedule",
	DEVICES = "devices",
	CONFIRM = "confirm"
};

export enum deployType { "event", "schedule" };

export enum DeviceFilterTypes { "GROUP", "LOCATION", "SEARCH", "STORE" }

export enum DeviceLEDSettingName {
	ButtonFront = "button_front",
	Front = "front",
	Lightbar = "lightbar"
}

export enum DeviceSizeTypes {
	TEN = "10",
	THIRTEEN = "13",
	TWENTY_ONE = "21",
	THIRTY_TWO = "32",
	FORTY_THREE = "43"
}

export enum DeviceStatusTypes {
	NOT_ASSIGNED_TO_GROUP = "0",
	NO_DEPLOYED_CONTENT = "1"
}

export enum DeviceSortTypes { ALPHA = "0", REVERSE_ALPHA = "1" }

export enum MediaContext { IMAGE = "image", VIDEO = "video", PAGE = "page" };

export enum MEDIA_ERRORS {
	LIBRARY_USAGE_ERROR,
	IMAGE_SIZE_ERROR,
	IMAGE_USAGE_ERROR,
	VIDEO_SIZE_ERROR,
	VIDEO_DURATION_ERROR,
	VIDEO_USAGE_ERROR,
	BANNER_WRONG_SIZE_ERROR,
	UNSUPPORTED_TYPE_ERROR,
	VIDEO_RESOLUTION_ERROR,
	FILE_READER_ERROR
}

export enum MediaFilterTypes {
	ALL = "0",
	FAVORITES = "1",
	VIDEOS = "2",
	IMAGES = "3",
	BANNERS = "4",
	SEARCH = "5",
	TAGS = "6",
	UNUSED = "7"
}

export enum Newsletter {
	ANALYTICS = "ad_analytics",
	DEPLOY = "deployment_reports",
	GROUP = "connect_users",
	HEALTH = "health_reports"
};

export enum SlideshowAnimations { NONE = "none", FADE = "fade", PUSH = "push", WIPE = "wipe" };

export enum SortTypes {
	ALPHA = "0",
	REVERSE_ALPHA = "1",
	NEWEST_FIRST = "2",
	OLDEST_FIRST = "3"
}

export enum TabType {
	group = "0",
	device = "1"
}

export enum ViewTypes { GRID = "0", LIST = "1", CHART = "2", SNAPSHOT = "3" }

import { LightbarFrame } from "@connect/Interfaces";

export const actionIdentifier = "action";
export const triggerIdentifier = "trigger";

/* eslint-disable camelcase */
export enum TriggerIcon {
	alarm_0 = "bell",
	alarm_1 = "bell",
	button_front = "circle",
	face = "smile",
	motion = "running",
	person = "male",
	network_request = "network-wired"
};

export enum TriggerName {
	alarm_0 = "Alarm 1",
	alarm_1 = "Alarm 2",
	button_front = "Help Button",
	face = "Face",
	motion = "Motion",
	person = "Person",
	network_request = "Network"
};

export enum ActionDataType {
	ad = "Play Ad",
	alarm_out = "Alarm Out",
	audio_level = "Audio Level",
	camera = "Full Camera",
	led_front = "LED On",
	led_button = "Button LED",
	lightbar = "Lightbar",
	network_request = "Network Request",
	playlist = "Playlist"
};
/* eslint-enable camelcase */

/*
 * Lightbar Preset Constants
 */
// Compile our list of preset lightbars from generators
export const LightbarPresets: LightbarFrame[][] = [
	// Slow red blink
	generateLightbarStrobe("#FF0000", 1000),
	// Slow blue blink
	generateLightbarStrobe("#0000FF", 1000),
	// Slow green blink
	generateLightbarStrobe("#00FF00", 1000),
	// Fast red blink
	generateLightbarStrobe("#FF0000", 250),
	// Fast blue blink
	generateLightbarStrobe("#0000FF", 250),
	// Fast green link
	generateLightbarStrobe("#00FF00", 250),
	// Police v1
	generateFlipper("#FF0000", "#0000FF", 250),
	// Police v2
	generatePoliceTwo(),
	// Red knight rider
	generateKnightRider("#FF0000", "#370000"),
	// White knight rider
	generateKnightRider("#FFFFFF", "#191919"),
	// Slow white eye
	generateEye("#FFFFFF", 300),
	// Fast green eye
	generateEye("#00FF00", 100),
	// Shifting rainbow
	generateRainbow()
];

/*
 * Lightbar Animation Generators
 */
export function generateEmptyFrame(duration: number) {
	return {
		colors: Array(12).fill("#000000"),
		duration
	} as LightbarFrame;
}

export function generateLightbarStrobe(color: string, duration: number) {
	return [
		{
			colors: Array(12).fill(color),
			duration
		},
		generateEmptyFrame(duration)
	] as LightbarFrame[];
}

export function generateFlipper(color1: string, color2: string, duration: number) {
	return [
		{
			colors: Array(12)
				.fill(color1, 0, 5)
				.fill(color2, 6, 11),
			duration
		},
		{
			colors: Array(12)
				.fill(color2, 0, 5)
				.fill(color1, 6, 11),
			duration
		}
	] as LightbarFrame[];
}

export function generatePoliceTwo() {
	// First part consists of alternating red/blue flashing sections
	const part1 = [
		generateEmptyFrame(100),
		generateFourColorFrame([ "#0000FF", "#000000", "#0000FF", "#000000" ], 100),
		generateEmptyFrame(100),
		generateFourColorFrame([ "#000000", "#FF0000", "#000000", "#FF0000" ], 100)
	];

	// Second part consists of two blue followed by two red flashes, but faster
	const part2 = [
		generateEmptyFrame(50),
		generateFourColorFrame([ "#0000FF", "#000000", "#0000FF", "#000000" ], 50),
		generateEmptyFrame(50),
		generateFourColorFrame([ "#0000FF", "#000000", "#0000FF", "#000000" ], 50),
		generateEmptyFrame(50),
		generateFourColorFrame([ "#000000", "#FF0000", "#000000", "#FF0000" ], 50),
		generateEmptyFrame(50),
		generateFourColorFrame([ "#000000", "#FF0000", "#000000", "#FF0000" ], 50)
	];

	// Repeat part 1 four times followed by part 2 four times
	return [
		...part1,
		...part1,
		...part1,
		...part1,
		...part2,
		...part2,
		...part2,
		...part2
	] as LightbarFrame[];
}

// Helper method for the 4 section police lights used in police V2
export function generateFourColorFrame(colors: string[], duration: number) {
	return {
		colors: Array(12)
			.fill(colors[0], 0)
			.fill(colors[1], 3)
			.fill(colors[2], 6)
			.fill(colors[3], 9),
		duration
	} as LightbarFrame;
}

export function generateKnightRider(color1: string, color2: string) {
	let frames: LightbarFrame[] = [];
	const duration = 50;

	// Buffer our colors on either side to provide room for the trailing LED
	let bufferedColors = Array(14).fill("#000000");
	bufferedColors[0] = color2;
	bufferedColors[1] = color1;
	bufferedColors[2] = color2;

	for (let i = 0; i < 12; i++) {
		// Add a frame with the buffered ends sliced off
		frames.push({
			colors: bufferedColors.slice(1, 13),
			duration
		} as LightbarFrame);
		// Shift our array to the right to move the LED
		bufferedColors.unshift(bufferedColors.pop());
	}

	// Add on the reverse of the frames to finish the animation loop
	return frames.concat(frames.slice(1, 12).reverse());
}

export function generateEye(color: string, duration: number) {
	let frames: LightbarFrame[] = [];
	// Start with an empty frame and empty colors
	let frameColors = Array(12).fill("#000000");
	frames.push(generateEmptyFrame(duration));

	// Start our eye from the center of the array
	let leftIndex = 5;
	let rightIndex = 6;

	for (let i = 0; i < 6; i++) {
		// Add our current state of the eye, then increment the eye index
		frames.push({
			colors: [ ...frameColors.fill(color, leftIndex, rightIndex + 1) ],
			duration
		} as LightbarFrame);
		leftIndex--;
		rightIndex++;
	}

	// Add on the reverse of the frames to finish the animation loop
	return frames.concat([ ...frames ].reverse());
}

export function generateRainbow() {
	let frames: LightbarFrame[] = [];
	let duration = 25;
	let rainbowColors: string[] = [
		"#de3031", "#de3031", "#f67504", "#f67504",
		"#ffb005", "#ffb005", "#54b436", "#54b436",
		"#0091da", "#0091da", "#8c328e", "#8c328e"
	];

	for (let i = 0; i < 12; i++) {
		// Add a frame of the current rainbow and then shift the rainbow to the right
		frames.push({
			colors: [ ...rainbowColors ],
			duration
		} as LightbarFrame);
		rainbowColors.unshift(rainbowColors.pop() || "");
	}

	return frames;
}

import * as React from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";

import { ActionSet, ActionType, Context, IPlaylist, TriggerType } from "@connect/Interfaces";
import { Notifications } from "@connect/Notifications";
import { Utils } from "@connect/Utils";
import ActionSetPropertiesPanelContent from "Components/Actions/ActionSetPropertiesPanelContent";
import AdActionPropertiesPanelContent from "Components/Actions/AdActionPropertiesPanelContent";
import PlaylistActionPropertiesPanelContent from "Components/Actions/PlaylistActionPropertiesPanelContent";
import AlarmActionPropertiesPanelContent from "Components/Actions/AlarmActionPropertiesPanelContent";
import AlarmTriggerPropertiesPanelContent from "Components/Actions/AlarmTriggerPropertiesPanelContent";
import AudioActionPropertiesPanelContent from "Components/Actions/AudioActionPropertiesPanelContent";
import ButtonTriggerPropertiesPanelContent from "Components/Actions/ButtonTriggerPropertiesPanelContent";
import CameraActionPropertiesPanelContent from "Components/Actions/CameraActionPropertiesPanelContent";
import FaceTriggerPropertiesPanelContent from "Components/Actions/FaceTriggerPropertiesPanelContent";
import PersonTriggerPropertiesPanelContent from "Components/Actions/PersonTriggerPropertiesPanelContent";
import LEDActionPropertiesPanelContent from "Components/Actions/LEDActionPropertiesPanelContent";
import LightbarActionPropertiesPanelContent from "Components/Actions/LightbarActionPropertiesPanelContent";
import MotionTriggerPropertiesPanelContent from "Components/Actions/MotionTriggerPropertiesPanelContent";
import NetworkActionPropertiesPanelContent from "Components/Actions/NetworkActionPropertiesPanelContent";
import ButtonLEDActionPropertiesPanelContent from "Components/Actions/ButtonLEDActionPropertiesPanelContent";
import DefaultPlaylistActionPropertiesPanelContent from
	"Components/Actions/DefaultPlaylistActionPropertiesPanelContent";
import { ButtonTypes, IconWeights } from "Components/Global/Button";
import { Colors } from "Components/Global/Constants";
import PropertiesPanel, { PropertiesPanelButton } from "Components/Global/PropertiesPanel";
import { createActionSetAsync, deleteActionSetAsync, updateActionSetAsync } from "Data/Actions/ActionsAsync";
import { setSelectPlaylistsModal } from "Data/Actions/UI/Modals";
import { updatePlaylistAsync } from "Data/Actions/Playlists";
import { hasPermission, PERMISSIONS } from "Data/Objects/Permissions";
import { getSelectedActionType, getSelectedTriggerType } from "Data/Selectors/ActionBuilder";
import { getActiveActionSet } from "Data/Selectors/Actions";
import { getActiveUuid } from "Data/Selectors/UI";
import { setSelectedActionComponent } from "Data/Actions/UI/ActionSetBuilder";
import NetworkTriggerPropertiesPanelContent from "Components/Actions/NetworkTriggerPropertiesPanelContent";

const mapDispatchToProps = (dispatch) => ({
	clearActionSet: (action: ActionSet) => dispatch(updateActionSetAsync(action)),
	clearSelected: () => dispatch(setSelectedActionComponent(-1, "")),
	copyActionSet: (action: ActionSet) => dispatch(createActionSetAsync(action)),
	deleteAction: (uuid: string) => dispatch(deleteActionSetAsync(uuid)),
	pushToActionsPage: () => dispatch(push("/actions")),
	setActiveAction: (id: string) => dispatch(push(`/actions/${ id }`)),
	showPlaylistModal: () => dispatch(setSelectPlaylistsModal(true, [], Context.ACTIONS)),
	updatePlaylist: (playlist: IPlaylist) => dispatch(updatePlaylistAsync(playlist))
});

const mapStateToProps = (state) => {
	const activeAction = getActiveActionSet(state);
	const activeUuid = getActiveUuid(state, "actions");

	return {
		activeAction,
		activeUuid,
		selectedAction: getSelectedActionType(state),
		selectedTrigger: getSelectedTriggerType(state)
	};
};

interface ActionsPagePropertiesPanelProps {
	activeAction: ActionSet;
	activeUuid: string;
	clearActionSet: (action: ActionSet) => void;
	clearSelected: () => void;
	copyActionSet: (action: ActionSet) => Promise<ActionSet>;
	selectedAction: ActionType;
	selectedTrigger: TriggerType;
	deleteAction: (uuid: string) => void;
	pushToActionsPage: () => void;
	setActiveAction: (id: string) => void;
	showPlaylistModal: () => void;
	updatePlaylist: (playlist: IPlaylist) => void;
}

export class ActionsPagePropertiesPanel extends React.Component<ActionsPagePropertiesPanelProps> {
	constructor(props: ActionsPagePropertiesPanelProps) {
		super(props);

		this.handleCopyActionSet = this.handleCopyActionSet.bind(this);
		this.promptClearActionSet = this.promptClearActionSet.bind(this);
		this.promptDeleteAction = this.promptDeleteAction.bind(this);
		this.selectPlaylists = this.selectPlaylists.bind(this);
		this.showPlaylistModal = this.showPlaylistModal.bind(this);
	}

	render() {
		return (
			<React.Fragment >
				<PropertiesPanel
					actions={ this.getAvailableActions() }
					properties={ this.renderCurrentPanelContent() }
					title={ this.getActiveTitle() }
				/>
			</React.Fragment>
		);
	}

	renderCurrentPanelContent() {
		const { selectedAction, selectedTrigger } = this.props;

		if (selectedAction) {
			switch (selectedAction) {
				case "ad":
					return <AdActionPropertiesPanelContent />;
				case "playlist":
					return <PlaylistActionPropertiesPanelContent />;
				case "alarm_out":
					return <AlarmActionPropertiesPanelContent />;
				case "audio_level":
					return <AudioActionPropertiesPanelContent />;
				case "camera":
					return <CameraActionPropertiesPanelContent />;
				case "led_front":
					return <LEDActionPropertiesPanelContent />;
				case "led_button":
					return <ButtonLEDActionPropertiesPanelContent />;
				case "lightbar":
					return <LightbarActionPropertiesPanelContent />;
				case "network_request":
					return <NetworkActionPropertiesPanelContent />;
				case "default":
					return <DefaultPlaylistActionPropertiesPanelContent />;
				default:
					return <ActionSetPropertiesPanelContent />;
			}
		} else {
			switch (selectedTrigger) {
				case "motion":
					return <MotionTriggerPropertiesPanelContent />;
				case "face":
					return <FaceTriggerPropertiesPanelContent />;
				case "alarm_0":
				case "alarm_1":
					return <AlarmTriggerPropertiesPanelContent />;
				case "button_front":
					return <ButtonTriggerPropertiesPanelContent />;
				case "person":
					return <PersonTriggerPropertiesPanelContent />;
				case "network_request":
					return <NetworkTriggerPropertiesPanelContent />;
				default:
					return <ActionSetPropertiesPanelContent />;
			}
		}
	}

	getActiveTitle() {
		const { selectedAction, selectedTrigger } = this.props;

		if (!selectedAction && !selectedTrigger) {
			return "Action Properties";
		}

		if (selectedAction) {
			switch (selectedAction) {
				case "ad":
					return "Ad Properties";
				case "alarm_out":
					return "Alarm Out Properties";
				case "audio_level":
					return "Audio Level Properties";
				case "camera":
					return "Camera Properties";
				case "led_front":
					return "LED Properties";
				case "led_button":
					return "Button LED Properties";
				case "lightbar":
					return "Lightbar Properties";
				case "network_request":
					return "Network Request Properties";
				default:
					return "Action Properties";
			}
		} else {
			switch (selectedTrigger) {
				case "motion":
					return "Motion Detection Properties";
				case "face":
					return "Face Detection Properties";
				case "alarm_0":
					return "Alarm Input #1 Properties";
				case "alarm_1":
					return "Alarm Input #2 Properties";
				case "button_front":
					return "Button Properties";
				case "network_request":
					return "Network Trigger Properties";
				default:
					return "Action Properties";
			}
		}
	}

	getAvailableActions(): PropertiesPanelButton[] {
		let actions: PropertiesPanelButton[] = [];
		const { activeUuid } = this.props;

		const deleteActionSet = {
			action: this.promptDeleteAction,
			icon: { name: "trash-alt", weight: "regular" as IconWeights },
			key: `${ activeUuid }_delete_action`,
			name: "Delete Action Set",
			type: "danger" as ButtonTypes
		};
		const copyActionSet = {
			action: this.handleCopyActionSet,
			icon: { name: "copy", weight: "regular" as IconWeights },
			key: `${ activeUuid }_duplicate_actionSet`,
			name: "Copy Action Set"
		};
		const clearActionSet = {
			action: this.promptClearActionSet,
			icon: { name: "eraser", weight: "solid" as IconWeights },
			key: `${ activeUuid }_clear_actionSet`,
			name: "Clear Action Set"
		};
		const addToPlaylist = {
			action: () => this.showPlaylistModal(),
			icon: { name: "th-list", weight: "solid" as IconWeights },
			key: `${ activeUuid }_add_to_playlist`,
			name: "Add to Playlist",
			type: "primary" as ButtonTypes,
			color: Colors.primaryBlue
		}

		if (hasPermission(PERMISSIONS.PLAYLISTS_MANAGE)) {
			actions.push(addToPlaylist);
		}

		actions.push(clearActionSet, copyActionSet, deleteActionSet);

		return actions;
	}

	handleCopyActionSet() {
		const { activeAction, copyActionSet, setActiveAction } = this.props;

		const copiedAction = Object.assign({}, activeAction, {
			name: Utils.copyName(activeAction.name)
		});

		copyActionSet(copiedAction)
			.then(({ uuid }) => {
				setActiveAction(uuid);
			});
	}

	promptClearActionSet() {
		const { activeAction, clearActionSet, clearSelected } = this.props;

		Notifications.confirm("Are you sure you want to clear this action set?",
			"Clearing an action set removes all triggers and actions.",
			"Clear Action Set", "Cancel", () => {
				clearSelected();
				clearActionSet(Object.assign({}, activeAction, {
					data: []
				}));
			});
	}

	promptDeleteAction() {
		const { activeAction, deleteAction, pushToActionsPage } = this.props;
		const { name, uuid } = activeAction;

		Notifications.confirm(`Delete ${name}?`,
			"Are you sure you want to delete this action set?",
			"Delete", "Cancel", () => {
				deleteAction(uuid);
				pushToActionsPage();
			});
	}

	selectPlaylists(playlists: IPlaylist[]) {
		const { activeAction, updatePlaylist } = this.props;
		const { name, uuid } = activeAction;

		playlists.forEach((playlist) => {
			updatePlaylist(Object.assign({}, playlist, {
				actionSet: { name, uuid }
			}));
			this.showPlaylistModal();
		});
	}

	showPlaylistModal() {
		this.props.showPlaylistModal();
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ActionsPagePropertiesPanel);

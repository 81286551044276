import * as React from "react";
import { connect } from "react-redux";

import { ActionSetActions, Context, CustomCSS, IPlaylist, PlaybackType, PlaylistAd } from "@connect/Interfaces";
import { ActionPropertiesPanelContent,
	ActionPropertiesPanelContentProps } from "Components/Actions/ActionPropertiesPanelContent";
import { Input, Icon } from "Components/Global/Common";
import { setSelectPlaylistsModal } from "Data/Actions/UI/Modals";
import { getSelectedActionType, getSelectedActionPlaylist,
	getSelectedActionPlaylistUuid,
	getSelectedActionPlaybackType} from "Data/Selectors/ActionBuilder";
import { Utils } from "@connect/Utils";
import { mapStateToProps as superMapStateToProps } from "Components/Actions/ActionPropertiesPanelContent";
import { getPlaylistAsync } from "Data/Actions/Playlists";
import OptionButton from "Components/Global/OptionButton";
import { updateSelectedActionPlayback } from "Data/Actions/ActionsAsync";
import SideScroller from "Components/Global/SideScroller";
import { Colors } from "Components/Global/Constants";

const mapStateToProps = (state) => {
	const superProps = superMapStateToProps(state);
	return {
		...superProps,
		activeActionType: getSelectedActionType(state),
		selectedPlaylist: getSelectedActionPlaylist(state),
		selectedPlaylistUuid: getSelectedActionPlaylistUuid(state),
		playbackType: getSelectedActionPlaybackType(state)
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		showPlaylistModal: () => dispatch(setSelectPlaylistsModal(true, [], Context.PLAYLIST_ACTION)),
		tryFetchPlaylist: (uuid: string) => dispatch(getPlaylistAsync({ uuid } as IPlaylist)),
		updatePlaybackType: (playback: PlaybackType) => dispatch(updateSelectedActionPlayback(playback))
	}
}

interface PlaylistActionPropertiesPanelContentProps extends ActionPropertiesPanelContentProps {
	selectedPlaylist: IPlaylist;
	selectedPlaylistUuid: string;
	playbackType: PlaybackType;
	showPlaylistModal: () => void;
	tryFetchPlaylist: (uuid: string) => void;
	updatePlaybackType: (playback: PlaybackType) => void;
}

export class PlaylistActionPropertiesPanelContent
	extends ActionPropertiesPanelContent<PlaylistActionPropertiesPanelContentProps> {
	constructor(props: PlaylistActionPropertiesPanelContentProps) {
		super(props);

		this.styles = {
			...this.styles,
			adThumbnail: {
				marginLeft: "auto",
				marginRight: "auto",
				marginBottom: 20,
				display: "block"
			},
			durationRow: {
				marginBottom: 10,
				marginTop: 10
			},
			label: {
				display: "inline-block",
				width: 100
			},
			playbackOptionsContainer: {
				width: "100%",
				display: "flex",
				flexDirection: "row",
				marginBottom: "6px",
				marginTop: "6px"
			},
			playbackOptionsButton: {
				padding: "5px",
				flex: 1,
				margin: 0
			},
			thumbnail: {
				height: 120,
				display: "flex"
			},
			playlistPreview: {
				width: "100%",
				marginBottom: 6
			},
			playbackDescription: {
				fontSize: "0.95em",
				color: Colors.slate
			},
			playbackLabelsContainer: {
				width: "100%",
				display: "flex"
			},
			playbackLabel: {
				flex: 1,
				textAlign: "center"
			}
		}

		const { icon, title } = ActionSetActions.playlist;

		this.icon = icon;
		this.title = title;

		this.setSequential = this.setSequential.bind(this);
		this.setRandom = this.setRandom.bind(this);
		this.renderPlaylistDetails = this.renderPlaylistDetails.bind(this);
		this.renderPlaylistPreview = this.renderPlaylistPreview.bind(this);
		this.renderThumbnail = this.renderThumbnail.bind(this);
		this.renderPlaybackDetails = this.renderPlaybackDetails.bind(this);
	}

	styles: CustomCSS;

	componentDidMount() {
		const { selectedPlaylist, selectedPlaylistUuid, tryFetchPlaylist } = this.props;
		if (selectedPlaylistUuid && !selectedPlaylist) {
			tryFetchPlaylist(selectedPlaylistUuid)
		};
	}

	setSequential() {
		this.props.updatePlaybackType("sequential");
	}

	setRandom() {
		this.props.updatePlaybackType("random");
	}

	render() {
		const { selectedPlaylist, selectedPlaylistUuid, showPlaylistModal, playbackType } = this.props;
		const { playbackOptionsButton, playbackOptionsContainer, playbackDescription,
			playbackLabelsContainer, playbackLabel
		 } = this.styles;

		return this.renderContainer(
			<React.Fragment>
				{ this.renderPlaylistPreview() }
				<Input
					button={{
						callback: showPlaylistModal,
						text: selectedPlaylistUuid ? "Replace" : "Select"
					}}
					key={ selectedPlaylistUuid }
					disabled
					hideMaxLength
					id={ "adSelector" }
					readOnly
					value={ selectedPlaylist ? selectedPlaylist.name : "" }
				/>
				{ this.renderPlaylistDetails() }
				<span>Playback Options:</span>
				<div style={playbackOptionsContainer}>
					<OptionButton
						selected={ playbackType === "sequential" }
						style={ { ...playbackOptionsButton, borderBottomLeftRadius: 6, borderTopLeftRadius: 6  } }
						onClick={this.setSequential}>
						<Icon
							name={ "list-ol" }
							iconWeight="regular" />
					</OptionButton>
					<OptionButton
						selected={ playbackType === "random" }
						style={ { ...playbackOptionsButton, borderBottomRightRadius: 6, borderTopRightRadius: 6  } }
						onClick={this.setRandom}>
						<Icon
							name={ "random" }
							iconWeight="regular" />
					</OptionButton>
				</div>
				<div style={ playbackLabelsContainer }>
					<span style={ playbackLabel }>Sequential</span>
					<span style={ playbackLabel }>Random</span>
				</div>
				<span style={ playbackDescription }>
					{ this.renderPlaybackDetails() }
				</span>
			</React.Fragment>
		);
	}

	renderPlaylistPreview() {
		const { selectedPlaylist, selectedPlaylistUuid } = this.props;

		if (!selectedPlaylistUuid || !selectedPlaylist || selectedPlaylist?.ads?.length === 0) {
			return;
		}

		const thumbnails = selectedPlaylist.ads.map(this.renderThumbnail);

		return (
			<div style={ this.styles.playlistPreview }>
				<SideScroller id={ selectedPlaylist.uuid }>
					{ thumbnails }
				</SideScroller>
			</div>
		)
	}

	renderThumbnail(ad: PlaylistAd) {
		// playlists are unique in this context but ads are not
		// so for each ad we additionally append the key with the playlist uuid
		return (
			<img
				key={ this.props.selectedPlaylistUuid + ad.uuid }
				src={ ad.thumbnail }
				style={ this.styles.thumbnail } />
		)
	}

	renderPlaylistDetails() {
		const { durationRow, label } = this.styles;
		const { selectedPlaylist } = this.props;

		if (!selectedPlaylist) {
			return null;
		}

		const playlistDuration =
			selectedPlaylist
				.ads
				.map((ad) => ad.duration)
				.reduce((totalDuration, nextDuration) => totalDuration + nextDuration, 0);

		return (
			<React.Fragment>
				<div style={ durationRow }>
					<div style={ label }>Playlist Duration: </div>
					<span>
						{ Utils.getFormattedDuration(playlistDuration) }
					</span>
				</div>
				<div style={ durationRow }>
					<div style={ label }>Total Ads: </div>
					<span>
						{ selectedPlaylist.ads.length }
					</span>
				</div>
			</React.Fragment>
		)
	}

	renderPlaybackDetails() {
		const { playbackType } = this.props;

		return playbackType === "sequential" ?
			"Plays a single ad sequentially (in order) from the selected Playlist." :
			"Plays a single ad randomly from the selected Playlist.";
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(PlaylistActionPropertiesPanelContent);

import * as React from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";

import { ActionSet, Context as Context, IAd } from "@connect/Interfaces";
import { Notifications } from "@connect/Notifications";
import { Modal } from "Components/Global/Common";
import PlaylistsPage from "Components/Playlists/PlaylistsPage";
import { setSelectPlaylistsModal } from "Data/Actions/UI/Modals";
import { updatePlaylistAsync } from "Data/Actions/Playlists";
import { Playlist } from "Data/Objects/Playlists";
import { getActiveActionSet } from "Data/Selectors/Actions";
import { getActiveAd } from "Data/Selectors/AdBuilder";
import { getCurrentSelectPlaylistsModalContext, getSelectPlaylistsModalVisibility } from "Data/Selectors/UI";
import { getActionSetAsync, updateSelectedActionPlaylist } from "Data/Actions/ActionsAsync";

interface ISelectPlaylistsModalProps {
	activeActionSet: ActionSet;
	activeAd: IAd;
	context: Context;
	hideModal: () => void;
	isVisible: boolean;
	setActivePlaylist: (uuid: string) => void;
	getActionSet: (uuid: string) => void;
	updatePlaylist: (playlist: Playlist) => Promise<void>;
	updateSelectedActionPlaylist: (playlistUuid: string) => Promise<void>;
}

const mapStateToProps = (state) => ({
	activeActionSet: getActiveActionSet(state),
	activeAd: getActiveAd(state),
	context: getCurrentSelectPlaylistsModalContext(state),
	isVisible: getSelectPlaylistsModalVisibility(state)
});

const mapDispatchToProps = (dispatch) => ({
	hideModal: () => dispatch(setSelectPlaylistsModal(false, [])),
	setActivePlaylist: (uuid: string) => dispatch(push(`/playlists/${ uuid }`)),
	getActionSet: (uuid: string) => dispatch(getActionSetAsync(uuid)),
	updatePlaylist: (playlist: Playlist) => dispatch(updatePlaylistAsync(playlist)),
	updateSelectedActionPlaylist: (playlistUuid: string) => dispatch(updateSelectedActionPlaylist(playlistUuid))
});

export class UnwrappedSelectPlaylistsModal extends React.Component<ISelectPlaylistsModalProps> {
	constructor(props: ISelectPlaylistsModalProps) {
		super(props);

		this.handlePlaylistsSelected = this.handlePlaylistsSelected.bind(this);
		this.hide = this.hide.bind(this);
	}

	render() {
		const { context, isVisible } = this.props;

		return (
			<Modal
				fullScreen
				modalKey="SelectPlaylistsModal"
				onCancel={ this.hide }
				visible={ isVisible }
				width="100%"
			>
				<PlaylistsPage
					context={ context }
					onCloseModal={ this.hide }
					onPlaylistsSelected={ this.handlePlaylistsSelected }
				/>
			</Modal>
		);
	}

	handlePlaylistsSelected(playlists: Playlist[]) {
		switch (this.props.context) {
			case Context.ACTIONS:
				return this.handleAddPlaylistToAction(playlists);
			case Context.ADS:
				return this.handleAddAdToPlaylist(playlists);
			case Context.PLAYLIST_ACTION:
				return this.handleSetPlaylistAction(playlists);
			default:
				return null;
		}
	}

	handleAddPlaylistToAction(playlists: Playlist[]) {
		const { activeActionSet, updatePlaylist, getActionSet } = this.props;

		playlists.forEach((playlist) => {
			const { name } = playlist;

			updatePlaylist(Object.assign({}, playlist, {
				actionSet: {
					name: activeActionSet.name,
					uuid: activeActionSet.uuid
				}
			})).then(() => {
				getActionSet(activeActionSet.uuid);
			});

			Notifications.success(`Action set successfully added to ${ name }`);
		});

		this.hide();
	}

	handleSetPlaylistAction(playlists: Playlist[]) {
		if (playlists && playlists.length === 1) {
			this.props.updateSelectedActionPlaylist(playlists[0].uuid)
				.then(() => {
					this.hide();
				});
		}
	}

	handleAddAdToPlaylist(playlists: Playlist[]) {
		const { activeAd, setActivePlaylist, updatePlaylist } = this.props;

		playlists.forEach((playlist) => {
			const { ads, name, uuid } = playlist;
			updatePlaylist(Object.assign({}, playlist, {
				ads: ads.concat(activeAd)
			})).then(() => {
				Notifications.success(`Ad successfully added to ${ name }`);
				if (playlists.length === 1) {
					setActivePlaylist(uuid);
				}
			})
		});

		this.hide();
	}

	hide() {
		this.props.hideModal();
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(UnwrappedSelectPlaylistsModal);
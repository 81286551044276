import { Col, Row } from "antd";
import * as React from "react";

import { ActionType, ComponentsViewTypes, CustomCSS, TriggerType } from "@connect/Interfaces";
import DraggableNewActionCard from "Components/Actions/DraggableNewActionCard";
import DraggableNewTriggerCard from "Components/Actions/DraggableNewTriggerCard";
import { Colors } from "Components/Global/Constants";

const { gray, white } = Colors;

interface ComponentsViewProps {
	type: ComponentsViewTypes;
	disabledTriggers?: TriggerType[];
}

export default class ComponentsView extends React.Component<ComponentsViewProps> {
	constructor(props: ComponentsViewProps) {
		super(props);

		this.styles = {
			container: {
				overflowX: "hidden",
				color: white
			},
			warning: {
				color: gray,
				fontSize: ".85em"
			},
			disabled: {
				color: gray,
				fontSize: 10,
				textAlign: "center",
				marginBottom: 10,
				width: "100%"
			}
		}
	}

	styles: CustomCSS;

	render() {
		const { type } = this.props;

		if (type === ComponentsViewTypes.TRIGGER) {
			return this.renderTriggersCards();
		}

		return this.renderActionsCards();
	}

	renderActionColumn(actionType: ActionType) {
		const { disabledTriggers } = this.props;
		const disableActions = (!disabledTriggers || disabledTriggers.length === 0);

		return (
			<Col span={ 11 }>
				<DraggableNewActionCard
					disabled={ disableActions }
					type={ ComponentsViewTypes.ACTION }
					actionType={ actionType }
				/>
			</Col>
		);
	}

	renderTriggerColumn(triggerType: TriggerType) {
		let disabled = this.checkDisabled(triggerType) && triggerType !== "network_request";

		return (
			<Col span={ 11 }>
				<DraggableNewTriggerCard
					disabled={ disabled }
					type={ ComponentsViewTypes.TRIGGER }
					triggerType={ triggerType }
				/>
			</Col>
		);
	}

	renderEmptyColumn() {
		return <Col span={ 11 } />;
	}

	renderTriggersCards() {
		return (
			<div style={ this.styles.container }>
				<Row type="flex" justify="center" gutter={22}>
					{ this.renderTriggerColumn("motion") }
					{ this.renderTriggerColumn("face") }
				</Row>
				<Row type="flex" justify="center" gutter={22}>
					{ this.renderTriggerColumn("alarm_0") }
					{ this.renderTriggerColumn("alarm_1") }
				</Row>
				<Row type="flex" justify="center" gutter={22}>
					{ this.renderTriggerColumn("button_front") }
					{ this.renderTriggerColumn("person") }
				</Row>
				<Row type="flex" justify="center" gutter={22}>
					{ this.renderTriggerColumn("network_request") }
					{ this.renderEmptyColumn() }
				</Row>
			</div>
		);
	}

	renderActionsCards() {
		return (
			<div style={ this.styles.container }>
				<Row type="flex" justify="center" gutter={22}>
					{ this.renderActionColumn("led_front") }
					{ this.renderActionColumn("audio_level") }
				</Row>
				<Row type="flex" justify="center" gutter={22}>
					{ this.renderActionColumn("ad") }
					{ this.renderActionColumn("camera") }
				</Row>
				<Row type="flex" justify="center" gutter={22}>
					{ this.renderActionColumn("alarm_out") }
					{ this.renderActionColumn("lightbar") }
				</Row>
				<Row type="flex" justify="center" gutter={22}>
					{ this.renderActionColumn("network_request") }
					{ this.renderActionColumn("led_button") }
				</Row>
				<Row type="flex" justify="center" gutter={22}>
					{ this.renderActionColumn("default") }
					{ this.renderActionColumn("playlist") }
				</Row>
			</div>
		);
	}

	renderWarning(warning: string) {
		return (
			<span style={this.styles.warning}>
				{ warning }
			</span>
		);
	}

	checkDisabled(triggerType: TriggerType) {
		const { disabledTriggers } = this.props;

		if (disabledTriggers && disabledTriggers.length) {
			return disabledTriggers.indexOf(triggerType) > -1;
		}

		return false;
	}
}